import { SVGAttributes, VFC } from "react"

export const HourGlass: VFC<SVGAttributes<SVGElement>> = (props) => (
  <svg
    data-token-name="HourGlass"
    width="1em"
    height="1em"
    fill="currentColor"
    {...props}
    viewBox="0 0 12 20"
  >
    <path d="M12 20L11.99 14L8 10L11.99 5.99L12 0H0V6L4 10L0 13.99V20H12ZM2 5.5V2H10V5.5L6 9.5L2 5.5Z" />
  </svg>
)
