/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum UrlLinkType {
    DOWNLOAD_LINK = 'DownloadLink',
    VIEWING_OR_LISTENING_LINK = 'ViewingOrListeningLink',
    WEBSITE = 'Website',
}
