/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IntentedMediaType {
    EDITION = 'Edition',
    EXPOSURE = 'Exposure',
    FILM = 'Film',
    INSTALLATION = 'Installation',
    PRESS = 'Press',
    PRESS_AND_RADIO = 'PressAndRadio',
}
