/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ContentType {
    FILE_GED = 'FileGed',
    FILE_LINK = 'FileLink',
    PAGE_CONTENT = 'PageContent',
    PAGE_LINK = 'PageLink',
    PBE_FILE_LINK = 'PbeFileLink',
}
