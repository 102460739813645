/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum SpecificDomainType {
    ANIMAL = 'Animal',
    ART_AND_CULTURE = 'ArtAndCulture',
    DISCOVERY = 'Discovery',
    EDUCATION = 'Education',
    ENVIRONMENT = 'Environment',
    HISTORY = 'History',
    INFORMATION = 'Information',
    NONE = 'None',
    OTHER = 'Other',
    POLICY = 'Policy',
    RELIGION = 'Religion',
    SCIENCE = 'Science',
    SOCIETY = 'Society',
    SPORT = 'Sport',
}
