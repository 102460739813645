/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ExploitationType {
    CORPORATE_BROADCASTING = 'CorporateBroadcasting',
    DELINEARIZED_BROADCASTING = 'DelinearizedBroadcasting',
    EXHIBITION = 'Exhibition',
    EXPLOITED_ON_MATERIAL = 'ExploitedOnMaterial',
    FESTIVAL = 'Festival',
    INTERNATIONAL_EXCHANGE = 'InternationalExchange',
    MOVIE_THEATER = 'MovieTheater',
    MUSEUM = 'Museum',
    OTHER_SCAM_EXPLOITATION = 'OtherScamExploitation',
    PAPER_OR_DIGITAL_PUBLISHING = 'PaperOrDigitalPublishing',
    PAPER_PRESS = 'PaperPress',
    PHONOGRAPHIC_EDITION = 'PhonographicEdition',
    RADIO_BROADCASTING = 'RadioBroadcasting',
    RETRANSMISSION = 'Retransmission',
    TELEVISION_BROADCASTING = 'TelevisionBroadcasting',
}
