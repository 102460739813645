/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ContactExternalIdType {
    BNF = 'Bnf',
    DAYLIMOTION_CHANNEL = 'DaylimotionChannel',
    ELECTRE = 'Electre',
    FACEBOOK_ACCOUNT = 'FacebookAccount',
    FLICKR = 'Flickr',
    INSTAGRAM_ACCOUNT = 'InstagramAccount',
    IPI_BASE_NUMBER = 'IpiBaseNumber',
    IPI_NAME_NUMBER = 'IpiNameNumber',
    LINKED_IN_ACCOUNT = 'LinkedInAccount',
    PAL_NUMBER = 'PalNumber',
    PINTEREST = 'Pinterest',
    TWITTER_ACCOUNT = 'TwitterAccount',
    VIMEO_CHANNEL = 'VimeoChannel',
    YOU_TUBE_CHANNEL = 'YouTubeChannel',
}
