export const verifySocialMediaLink = async (
  socialMedia: string,
  link: string,
): Promise<boolean> => {
  const pattern = PATTERN_REGEX.find(
    (regex) => regex.name === socialMedia,
  )?.pattern

  if (!pattern) return true

  return !!pattern?.test(link)
}

export const PATTERN_REGEX = [
  {
    name: "Vimeo",
    pattern: /http(s)?:\/\/(www\.)?(vimeo)\.com\/[A-z0-9_\-.]+\/?/,
  },
  {
    name: "Twitter",
    pattern: /http(s)?:\/\/(www\.)?(twitter)\.com\/[A-z0-9_\-.]+\/?/,
  },
  {
    name: "Pinterest",
    pattern: /http(s)?:\/\/(www\.)?(pinterest)\.fr\/[A-z0-9_\-.]+\/?/,
  },
  {
    name: "LinkedIn",
    pattern: /http(s)?:\/\/([\w]+\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?/,
  },
  {
    name: "Instagram",
    pattern: /http(s)?:\/\/(www\.)?(instagram)\.com\/[A-z0-9_\-.]+\/?/,
  },
  {
    name: "Facebook",
    pattern: /http(s)?:\/\/(www\.)?(facebook|fb)\.com\/[A-z0-9_\-.]+\/?/,
  },
  {
    name: "Youtube",
    pattern:
      /((http | https): \/\/|)(www\.|)youtube\.com\/(channel\/|user\/)[a-zA-Z0-9-]{1,}/,
  },
  {
    name: "VimeoChannel",
    pattern: /http(s)?:\/\/(www\.)?(vimeo)\.com\/[A-z0-9_\-.]+\/?/,
  },
  {
    name: "TwitterAccount",
    pattern: /http(s)?:\/\/(www\.)?(twitter)\.com\/[A-z0-9_\-.]+\/?/,
  },
  {
    name: "LinkedInAccount",
    pattern: /http(s)?:\/\/([\w]+\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?/,
  },
  {
    name: "InstagramAccount",
    pattern: /http(s)?:\/\/(www\.)?(instagram)\.com\/[A-z0-9_\-.]+\/?/,
  },
  {
    name: "FacebookAccount",
    pattern: /http(s)?:\/\/(www\.)?(facebook|fb)\.com\/[A-z0-9_\-.]+\/?/,
  },
  {
    name: "YouTubeChannel",
    pattern:
      /((http | https): \/\/|)(www\.|)youtube\.com\/(channel\/|user\/)[a-zA-Z0-9-]{1,}/,
  },
]
