/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ApplicationStatus {
    ABANDONED_ADMIN = 'Abandoned_Admin',
    ABANDONED_SESSION_EXPIRED = 'Abandoned_SessionExpired',
    ABANDONED_TOKEN_EXPIRED = 'Abandoned_TokenExpired',
    ABANDONED_USER = 'Abandoned_User',
    JURY_REVIEW_ACTION_PENDING_AUTHORS = 'JuryReview_ActionPending_Authors',
    JURY_REVIEW_ACTION_PENDING_PROJECT = 'JuryReview_ActionPending_Project',
    JURY_REVIEW_NOT_SELECTED = 'JuryReview_NotSelected',
    JURY_REVIEW_REVISION = 'JuryReview_Revision',
    JURY_REVIEW_SPONSORED = 'JuryReview_Sponsored',
    JURY_REVIEW_UNDER_REVIEW = 'JuryReview_UnderReview',
    JURY_REVIEW_WINNING = 'JuryReview_Winning',
    PRE_SELECTED_ACTION_PENDING_QUESTIONS = 'PreSelected_ActionPending_Questions',
    PRESELECTED_REVISION = 'Preselected_Revision',
    PRESELECTED_UNDER_REVIEW = 'Preselected_UnderReview',
    RECEIVED_NOT_ELIGIBLE = 'Received_NotEligible',
    RECEIVED_NOT_SELECTED = 'Received_NotSelected',
    RECEIVED_UNDER_REVIEW = 'Received_UnderReview',
    REGISTRATION_PENDING = 'Registration_Pending',
    REGISTRATION_TO_BE_COMPLETED = 'Registration_ToBeCompleted',
}
