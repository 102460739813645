/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PbeDocumentType {
    ATTACHMENT_APPLICATION_FILE = 'AttachmentApplicationFile',
    ATTACHMENT_AUTHOR_CV = 'AttachmentAuthorCv',
    ATTACHMENT_CONVENTION = 'AttachmentConvention',
    ATTACHMENT_DECLARATION_OF_HONOR = 'AttachmentDeclarationOfHonor',
    ATTACHMENT_LOGO_MENTION = 'AttachmentLogoMention',
    ATTACHMENT_RIB = 'AttachmentRib',
    ATTACHMENT_SUPPORT_LETTER = 'AttachmentSupportLetter',
    ATTACHMENT_TAX_DOCUMENT = 'AttachmentTaxDocument',
    ATTACHMENT_WITHHOLDING_TAX_EXEMPTION = 'AttachmentWithholdingTaxExemption',
    MODEL_CALL_PROJECT = 'ModelCallProject',
    MODEL_GENERIC = 'ModelGeneric',
    MODEL_REGULATION = 'ModelRegulation',
}
